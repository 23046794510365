/*
============================================================================================
* File Name: main.scss
* This is the main scss file which contains all scss modules and converts into .css 
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Poppins',sans-serif; }

html {
  height: 100vh;
  font-size: 62.5%;
  background-color: #f0f0f0; }

body {
  color: #66615b;
  font-size: 14px;
  background-color: transparent; }

.app-container {
  width: 100%; }
  .app-container .main-container {
    width: 100%;
    min-height: 80vh; }
    .app-container .main-container .main-content {
      margin: 20px 20px 20px 100px; }

@media (max-width: 768px) {
  .app-container .main-container .main-content {
    margin: 50px 10px; } }

.text-muted {
  color: #dddddd; }

.text-primary,
.text-primary:hover {
  color: #51cbce !important; }

.text-info,
.text-info:hover {
  color: #51bcda !important; }

.text-success,
.text-success:hover {
  color: #4a916a !important;
  background-color: #e1f5ea;
  padding: 5px; }

.text-warning,
.text-warning:hover {
  color: #fab21f !important;
  background-color: #feedcb;
  padding: 5px; }

.text-danger,
.text-danger:hover {
  color: #f5593d !important; }

.text-danger-bg,
.text-danger-bg:hover {
  color: #f5593d !important;
  background-color: #ffdddd;
  padding: 5px; }

mark {
  background-color: lightyellow; }

.glyphicon {
  line-height: 1; }

.heart {
  color: #eb5e28;
  animation: heathing 1s ease infinite; }

.brand-button {
  background: #0070c0;
  color: #ffffff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px; }

.list-table-div {
  background-color: white; }

.list-table-div-meter-readings {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 50%; }

.list-table-div-meter-readings-one {
  background-color: white;
  width: 50%;
  display: flex; }

.report-button-space {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #0070c0;
  color: #fff;
  cursor: pointer;
  width: 25%;
  font-size: 12px; }

th {
  color: #999998; }

tr {
  height: 10px; }

.icon-td {
  width: 3%;
  padding: 3px; }

.list-table-icon {
  font-size: 22px !important;
  font-weight: bold;
  border-radius: 50%;
  color: #0070c0; }

.fa-caret-down, .fa-caret-up {
  padding-left: 5px; }

@media (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap; } }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.rotate {
  animation: rotation 2s infinite linear; }

.not-active {
  pointer-events: none;
  cursor: default;
  opacity: 0;
  margin-left: -20%; }

.list-wrapper {
  padding-bottom: 50px !important; }

input, tr, td, p, div {
  text-transform: none; }

input {
  text-transform: none; }

.app-outer-div {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh; }

::-webkit-input-placeholder {
  font-style: italic; }

:-moz-placeholder {
  font-style: italic; }

::-moz-placeholder {
  font-style: italic; }

:-ms-input-placeholder {
  font-style: italic; }

.icon {
  font-style: normal; }

.back-button {
  font-weight: bolder; }

.pl1 {
  padding-left: 30px !important; }

.pl2 {
  padding-left: 60px !important; }

.MuiIconButton-root {
  font-size: 22px !important;
  color: #0070c0 !important; }

.MuiToolbar-root {
  align-items: center !important; }

.MuiTablePagination-root {
  font-size: 12px !important; }

MuiSelect-icon {
  top: calc(50% - 8px) !important; }

.MTablePaginationInner-root-14 {
  color: black !important; }

element.style {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: inherit;
  text-align: center;
  align-self: center; }

.MuiTypography-caption {
  color: red !important;
  font-size: 10px !important; }

.MuiInputBase-input {
  font-size: 12px !important; }

.MuiTablePagination-toolbar {
  padding-top: 1% !important; }

.MuiTablePagination-root:last-child {
  padding: 0;
  border-bottom: none; }

ul.breadcrumb1 {
  list-style: none;
  background-color: none !important; }

ul.breadcrumb1 li {
  display: inline;
  font-size: 14px; }

ul.breadcrumb1 li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0"; }

ul.breadcrumb1 li a {
  color: #0275d8;
  text-decoration: none; }

ul.breadcrumb1 li a:hover {
  color: #01447e;
  text-decoration: underline; }

.breadcrumb1 {
  background-color: none !important; }

.style_enter__29LyQ {
  text-transform: none !important; }

.csv-title {
  color: #0070c0;
  font-size: 16px;
  font-weight: bold; }

.csv-link-label {
  color: #0070c0;
  margin-left: 10px;
  font-size: 12px; }

.csv-link-label:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #4a916a; }

.error-msg {
  text-transform: none; }

.MuiIcon-root {
  font-size: 1.8rem !important; }

button:focus {
  outline: none !important; }

.MuiIconButton-colorInherit {
  color: white; }

.text-transform-none {
  text-transform: none !important; }

.pl-0 {
  padding-left: 0px !important; }

.infobox-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #0A043B;
  opacity: 0.85;
  padding: 12px;
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
  border-radius: 10px; }

.infobox-wrapper :hover {
  cursor: pointer; }

.inner-info {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-transform: capitalize; }

.inner-info :first-child {
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  line-height: 20px; }

.inner-info :nth-child(2) {
  margin-top: 10px;
  text-decoration: underline;
  float: right;
  margin-left: 100%;
  margin-right: 35%;
  font-size: 12;
  font-weight: 500;
  width: 70px;
  line-height: 20px; }

.underline-italic {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer; }

.gray-icon {
  color: gray; }

.camera-icon {
  color: #0070c0 !important;
  cursor: pointer; }

/*
============================================================================================
* File Name: _button.scss
* This file contains all button styles that are globally appicable
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.brand-button {
  width: 150px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #0070c0;
  font-size: 16px;
  color: #fff;
  cursor: pointer; }
  .brand-button:hover {
    background: #2674c8; }

.cancel_button {
  width: 150px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #ffffff;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  margin-left: 2%;
  border: 1px solid grey;
  color: #000000; }
  .cancel_button:hover {
    background: #f2f2f2; }

/*
*
* Buttons
*/
.button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 14px 35px;
  font-size: 14px;
  line-height: 1.3;
  border: 2px solid;
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out; }

.button-block {
  display: block;
  width: 100%; }

.button-default,
.button-default:focus {
  color: #d6d6d6;
  background-color: #2c343b;
  border-color: #2c343b; }

.button-default:hover,
.button-default:active {
  color: #ffffff;
  background-color: #00528d;
  border-color: #00528d; }

.button-primary,
.button-primary:focus {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-primary:hover,
.button-primary:active {
  color: #ffffff;
  /* background-color: #ca5217;
	border-color: #ca5217; */
  background-color: #00528d;
  border-color: #00528d; }

.bg-primary .button-primary,
.bg-primary .button-primary:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.bg-primary .button-primary:hover,
.bg-primary .button-primary:active {
  color: #ffffff;
  background-color: #ca5217;
  border-color: #ca5217; }

/* Grey Button Start */
.button-grey,
.button-grey:focus {
  color: #ffffff;
  background-color: #B8B8B9;
  border-color: #B8B8B9; }

.button-grey:hover,
.button-grey:active {
  color: #ffffff;
  /* background-color: #ca5217;
	border-color: #ca5217; */
  background-color: #ca5217;
  border-color: #ca5217; }

.bg-grey .button-grey,
.bg-grey .button-grey:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.bg-grey .button-grey:hover,
.bg-grey .button-grey:active {
  color: #ffffff;
  background-color: #ca5217;
  border-color: #ca5217; }

/* Grey Button Ends */
.button-gray-100,
.button-gray-100:focus {
  color: #151515;
  background-color: #f3f4f9;
  border-color: #f3f4f9; }

.button-gray-100:hover,
.button-gray-100:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-secondary,
.button-secondary:focus {
  color: #ffffff;
  background-color: #ca5217;
  border-color: #ca5217; }

.button-secondary:hover,
.button-secondary:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-blue-9,
.button-blue-9:focus {
  color: #ffffff;
  background-color: #49555e;
  border-color: #49555e; }

.button-blue-9:hover,
.button-blue-9:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-blue-9 .icon,
.button-blue-9:active .icon,
.button-blue-9:focus .icon {
  color: #0070c0; }

.button-blue-9:hover .icon {
  color: #ffffff; }

.button-default-outline,
.button-default-outline:focus {
  color: #151515;
  background-color: transparent;
  border-color: #e0e0e0; }

.button-default-outline:hover,
.button-default-outline:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-primary-outline,
.button-primary-outline:focus {
  color: #0070c0;
  background-color: transparent;
  border-color: #0070c0; }

.button-primary-outline:hover,
.button-primary-outline:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.bg-primary .button-primary-outline,
.bg-primary .button-primary-outline:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff; }

.bg-primary .button-primary-outline:hover,
.bg-primary .button-primary-outline:active {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.button-gray-800-outline,
.button-gray-800-outline:focus {
  color: #151515;
  background-color: transparent;
  border-color: #151515; }

.button-gray-800-outline:hover,
.button-gray-800-outline:active {
  color: #ffffff;
  background-color: #151515;
  border-color: #151515; }

.button-ghost {
  border: 0;
  background-color: transparent; }

.button-ghost:hover {
  color: #ffffff;
  background: #0070c0; }

.button-facebook,
.button-facebook:focus {
  color: #ffffff;
  background-color: #4d70a8;
  border-color: #4d70a8; }

.button-facebook:hover,
.button-facebook:active {
  color: #ffffff;
  background-color: #6585b9;
  border-color: #6585b9; }

.button-twitter,
.button-twitter:focus {
  color: #ffffff;
  background-color: #02bcf3;
  border-color: #02bcf3; }

.button-twitter:hover,
.button-twitter:active {
  color: #ffffff;
  background-color: #21cbfd;
  border-color: #21cbfd; }

.button-google,
.button-google:focus {
  color: #ffffff;
  background-color: #e2411e;
  border-color: #e2411e; }

.button-google:hover,
.button-google:active {
  color: #ffffff;
  background-color: #e76042;
  border-color: #e76042; }

.button-primary-hold {
  margin-left: 50%;
  font-size: 12px; }

.button-primary-hold,
.button-primary-hold:focus {
  color: #0070c0;
  background-color: transparent;
  border-color: #0070c0; }

.button-primary-hold:hover,
.button-primary-hold:active {
  color: #ffffff;
  background-color: #151515;
  border-color: #151515; }

.bg-primary .button-primary-hold,
.bg-primary .button-primary-hold:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff; }

.bg-primary .button-primary-hold:hover,
.bg-primary .button-primary-hold:active {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.button-primary-reject {
  margin-left: 10px;
  font-size: 12px; }

.button-primary-reject,
.button-primary-reject:focus {
  color: #ffffff;
  background-color: red;
  border-color: red; }

.button-primary-reject:hover,
.button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515; }

.bg-primary .button-primary-reject,
.bg-primary .button-primary-reject:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.bg-primary .button-primary-reject:hover,
.bg-primary .button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515; }

.button-shadow {
  box-shadow: 0 9px 21px 0 rgba(214, 214, 214, 0.6); }

.button-shadow:hover {
  box-shadow: 0 9px 10px 0 rgba(214, 214, 214, 0.6); }

.button-shadow:focus,
.button-shadow:active {
  box-shadow: none; }

.button-primary-approved,
.button-primary-approved:focus {
  color: #ffffff;
  background-color: darkgreen;
  border-color: darkgreen; }

.button-primary-approved:hover,
.button-primary-approved:active {
  color: #ffffff;
  background-color: green;
  border-color: green; }

.button-shadow-1 {
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18); }

.button-xs {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .1em;
  border-radius: 4px; }

.button-sm {
  font-weight: 500;
  letter-spacing: .1em;
  padding: 10px 25px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 4px; }

.button-lg {
  font-weight: 500;
  letter-spacing: .05em;
  padding: 17px 40px;
  font-size: 14px;
  line-height: 1.5; }

.button-xl {
  letter-spacing: .075em;
  padding: 16px 50px;
  font-size: 16px;
  line-height: 28px; }

@media (min-width: 992px) {
  .button-xl {
    padding: 20px 80px; } }

.button-circle {
  border-radius: 30px; }

.button-round-1 {
  border-radius: 5px; }

.button-round-2 {
  border-radius: 10px; }

.button.button-icon,
.button-icon .button-inner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle; }

.button-icon-right,
.button-icon-right .button-inner {
  flex-direction: row-reverse; }

.button-icon .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: inherit;
  font-size: 1.55em;
  line-height: 1em;
  transition: color inherit; }

.button-icon-left .icon {
  padding-right: 11px; }

.button-icon-right .icon {
  padding-left: 11px; }

.button-icon.button-xs .icon {
  padding-right: 5px; }

.button-icon.button-xs .button-icon-right .icon {
  padding-left: 5px; }

.button-icon.button-sm .icon {
  top: .05em;
  font-size: 1.2em;
  padding-right: 8px; }

.button-icon.button-sm .button-icon-right .icon {
  padding-left: 8px; }

.button.button-icon-only {
  padding: 5px; }

.btn-primary {
  border-radius: 3px;
  font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 700;
  letter-spacing: .05em;
  text-transform: uppercase;
  transition: .33s; }

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  color: #ffffff;
  background: #0070c0;
  border-color: #0070c0; }

.btn-primary:hover {
  color: #ffffff;
  background: #000000;
  border-color: #000000; }

.button-group {
  display: flex; }

.button-group .button {
  margin: 0 2px;
  flex-grow: 1; }

.button-group .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.button-group .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.group .button-fixed-size {
  display: inline-flex; }

.button-fixed-size {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 5px; }

.button-fixed-size img {
  width: auto;
  height: auto;
  max-width: 90%; }

@media (min-width: 576px) {
  .button-fixed-size {
    width: 177px; } }

label.button input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/*
============================================================================================
* File Name: _login-page.scss
* This file contains all Login Page Styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.outer_login_div {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center; }
  .outer_login_div input {
    text-transform: none; }

.main_div {
  width: 365px;
  background: #ffffff;
  padding: 15px 30px 30px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15); }
  .main_div .title-login {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #2268b3;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none; }
  .main_div .title-client-name {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #2268b3;
    margin-top: 17px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none; }
  .main_div .brand_logo_view {
    margin-top: 0px;
    display: flex;
    justify-content: center; }
    .main_div .brand_logo_view img {
      width: auto;
      height: 60px; }
  .main_div form {
    margin-top: 25px; }
    .main_div form .login-dropdown {
      height: 100%;
      width: 100%;
      outline: none;
      border: 1px solid lightgrey;
      border-radius: 5px;
      padding-left: 50px;
      font-size: 17px;
      transition: all 0.3s ease; }
    .main_div form .input_box {
      height: 50px;
      width: 100%;
      position: relative;
      margin-top: 15px; }
      .main_div form .input_box input {
        height: 100%;
        width: 100%;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding-left: 50px;
        font-size: 17px;
        transition: all 0.3s ease; }
        .main_div form .input_box input :focus {
          border-color: red; }
      .main_div form .input_box .icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        color: grey;
        font-size: 18px;
        font-style: normal; }
      .main_div form .input_box button {
        height: 100%;
        width: 100%;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        font-size: 17px;
        transition: all 0.3s ease; }
    .main_div form .option_div {
      margin-top: 5px;
      display: flex;
      justify-content: space-between; }
      .main_div form .option_div .check_box {
        display: flex;
        align-items: center; }
      .main_div form .option_div span {
        margin-left: 5px;
        font-size: 16px;
        color: #333; }
      .main_div form .option_div .forget_div a {
        font-size: 16px;
        color: grey; }
    .main_div form .button button {
      padding-left: 0;
      background: #4267B2;
      color: #fff;
      border: none;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s linear;
      text-transform: uppercase; }
      .main_div form .button button:hover {
        background: #4267B2; }
    .main_div form .error_msg {
      font-size: 12px; }

.login-warning-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 5px 20px;
  text-transform: none; }
  .login-warning-msg label {
    text-align: center; }

/*
============================================================================================
* File Name: _meter-page.scss
* This file contains Add Meter Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.meter_input_class {
  height: 100%;
  margin: 15px;
  border-radius: 5px;
  border-width: 1px;
  padding: 8px;
  outline: none;
  border: 1px solid lightgrey;
  font-size: 12px;
  transition: all 0.3s ease; }

.meter-data {
  width: 88% !important;
  margin-left: 9% !important;
  background-color: #fff;
  margin-right: 7.5%; }

.meter-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.meter-add-button {
  background: #4267B2;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px; }

.action-text-alignment {
  text-align: center; }

.table tr {
  cursor: pointer; }

.table .serial-no-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.table .meter-type-td {
  width: 16%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .meter-unit-td {
  width: 6%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: none; }

.table .meter-status-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .difference-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  text-align: center;
  font-weight: normal; }

.table .meter-amr-td {
  width: 14%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .consumer-no-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .meter-assign-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  padding-left: 30px; }

.table .action-td {
  width: 17%;
  font-size: 25px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  padding-left: 18px; }

.table .action-div {
  width: 100%; }
  .table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }
  .table .action-div .fa-edit {
    font-size: 22px;
    color: #0070c0; }
  .table .action-div .fa-trash {
    font-size: 22px; }
  .table .action-div .fa-eye {
    font-size: 22px;
    margin-left: 10px; }

.iconRedColor {
  color: red; }

.iconGreenColor {
  color: green; }

.align {
  text-align: center; }

@media (max-width: 1024px) {
  .meter-data {
    width: 100% !important; }
  .table .consumer-no-td {
    width: 12%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important;
    font-weight: normal; }
  .table .action-td {
    width: 25%;
    font-size: 25px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important; } }

@media (max-width: 414px) {
  .meter-data {
    width: 100% !important; }
  .align-icon {
    margin-left: 0px !important; } }

/*
============================================================================================
* File Name: _add-meter-page.scss
* This file contains Add Meter Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
:root {
  --blue: $BRAND_COLOR
; }

.add-meter-main-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }
  .add-meter-main-view .create-meter-div {
    background-color: #fff;
    width: 70%;
    border: 1px;
    margin-top: 3.5%; }
    .add-meter-main-view .create-meter-div label {
      display: inline-block; }
    .add-meter-main-view .create-meter-div .meter-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px; }
      .add-meter-main-view .create-meter-div .meter-icon-wrapper h4 {
        color: #0070c0;
        font-weight: 700;
        letter-spacing: 1px; }
    .add-meter-main-view .create-meter-div .list-table-icon {
      font-size: 32px;
      font-weight: bold;
      border-radius: 50%;
      color: #0070c0; }
    .add-meter-main-view .create-meter-div form {
      padding: 0px 50px;
      margin-bottom: 20px; }
    .add-meter-main-view .create-meter-div .form-label {
      color: #999998;
      font-family: avenir;
      font-size: 14px;
      margin-bottom: 0;
      margin-left: 8px; }
    .add-meter-main-view .create-meter-div .form-control {
      background-color: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 4px;
      color: #66615b;
      font-size: 14px;
      transition: background-color 0.3s ease 0s;
      padding-right: 0 !important;
      padding: 7px 12px;
      height: 40px;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .add-meter-main-view .create-meter-div .form-control:focus {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 !important; }
    .add-meter-main-view .create-meter-div .add-meter-input {
      text-align: left;
      color: #999998;
      border-radius: 8px;
      font-weight: 600;
      width: 100%;
      height: 50px;
      text-transform: none; }
    .add-meter-main-view .create-meter-div .add-meter-input:focus {
      color: black; }
    .add-meter-main-view .create-meter-div input.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-meter-main-view .create-meter-div textarea.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-meter-main-view .create-meter-div .error-msg {
      padding-left: 1.2%;
      font-size: 12px; }
    .add-meter-main-view .create-meter-div .input-label-group {
      margin-bottom: 20px; }
  .add-meter-main-view .meter-tab--selected {
    border-bottom: 1px solid #0070c0; }
  .add-meter-main-view .react-tabs__tab--selected {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0070c0 !important; }
  .add-meter-main-view .react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 0px;
    padding: 0; }
  .add-meter-main-view .react-tabs__tab {
    border: none;
    padding: 0px 12px;
    height: 60px; }
  .add-meter-main-view .react-tabs__tab:focus:after {
    height: 0px; }
  .add-meter-main-view .meter-tab-panel--selected {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%; }
  .add-meter-main-view .set-rtc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px; }

.add-meter-main-view .add-select-main-div .css-1dimb5e-singleValue {
  color: #999998; }

@media (max-width: 414px) {
  .add-meter-main-view .create-meter-div {
    width: 100%; } }

@media (max-width: 1112px) {
  .cancel_button {
    width: 135px; } }

.switch-div {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.toggle-switch {
  margin-left: 40%;
  position: relative;
  width: 50px;
  height: 24px; }

/* Hide default checkbox */
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The track */
.slider {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s; }

/* The circular slider */
.slider::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s; }

/* Toggled state */
.toggle-switch input:checked + .slider {
  background-color: #4caf50; }

.toggle-switch input:checked + .slider::before {
  transform: translateX(26px); }

/*
============================================================================================
* File Name: _navigation-bar.scss
* This file contains all Navigation Bar Styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.top-navigation-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 9999; }
  .top-navigation-bar-container .top-navigation-bar {
    width: 100%;
    padding: 8px;
    box-shadow: 0 0 10px 1px rgba(100, 100, 100, 0.2);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .top-navigation-bar-container .top-navigation-bar label {
      margin: 0;
      font-weight: normal; }
    .top-navigation-bar-container .top-navigation-bar .top-navigation-bar-icon-container {
      padding: 0 10px;
      display: flex;
      align-items: center; }
      .top-navigation-bar-container .top-navigation-bar .top-navigation-bar-icon-container .top-navigation-bar-icon {
        font-size: 22px;
        color: #0070c0; }
    .top-navigation-bar-container .top-navigation-bar .logo-container {
      margin-left: 6vw;
      padding-left: 10px; }
      .top-navigation-bar-container .top-navigation-bar .logo-container a img {
        width: 160px; }
    .top-navigation-bar-container .top-navigation-bar .logo-container-one {
      margin-left: 1vw;
      padding-left: 10px; }
      .top-navigation-bar-container .top-navigation-bar .logo-container-one a {
        display: block;
        width: 90px;
        height: 70px;
        overflow: hidden;
        position: relative;
        transition: transform 0.3s ease; }
        .top-navigation-bar-container .top-navigation-bar .logo-container-one a img {
          padding: 15px;
          width: 100%;
          height: 100%;
          transition: transform 0.3s ease;
          transform: scale(1.5); }
        .top-navigation-bar-container .top-navigation-bar .logo-container-one a:hover img {
          transform: scale(1.5);
          /* Zoom effect on hover */ }
    .top-navigation-bar-container .top-navigation-bar .logo-container-two {
      margin-left: 1vw;
      margin-right: 2vw;
      padding-left: 5px; }
      .top-navigation-bar-container .top-navigation-bar .logo-container-two a {
        display: block;
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        transition: transform 0.3s ease; }
        .top-navigation-bar-container .top-navigation-bar .logo-container-two a img {
          padding: 15px;
          width: 100%;
          height: 100%;
          transition: transform 0.3s ease;
          transform: scale(1.2); }
        .top-navigation-bar-container .top-navigation-bar .logo-container-two a:hover img {
          transform: scale(1.5);
          /* Zoom effect on hover */ }
    .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container {
      margin-right: 6vw;
      display: flex;
      align-items: center; }
      .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .site-select-dropdown-container {
        margin-right: 45px;
        display: flex;
        align-items: center; }
      .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .site-select-dropdown.css-b62m3t-container {
        width: 19em; }
      .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .css-1xc3v61-indicatorContainer {
        color: #0070c0; }
      .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .user-info-container {
        display: flex;
        align-items: center; }
        .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .user-info-container .top-navigation-bar-icon-container {
          cursor: pointer; }

.site-client-name {
  font-size: 13px;
  font-weight: 700;
  color: #0070C0;
  letter-spacing: 0.5px; }

@media (max-width: 768px) {
  .top-navigation-bar-container .top-navigation-bar .logo-container {
    margin-left: 4vw; }
    .top-navigation-bar-container .top-navigation-bar .logo-container a img {
      width: 120px; }
  .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container {
    margin-right: 3vw; }
    .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .site-select-dropdown-container {
      margin-right: 1vw; }
    .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .user-info-container {
      display: none; } }

/*
============================================================================================
* File Name: _user-page.scss
* This file contains User Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.user-button-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.list-table-div .center-heading {
  text-align: center; }

.list-table th {
  font-weight: bolder;
  font-size: 15px; }

.role-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 7.5%;
  margin-right: 7.5%;
  width: 85%;
  padding: 0px 20px; }
  .role-div .roles-label {
    font-size: 16px;
    font-weight: 900;
    text-decoration: underline;
    cursor: pointer;
    color: #0070c0; }

.user-list-table tr {
  cursor: pointer; }

.user-list-table .name-td {
  width: 18%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.user-list-table .email-td {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: none; }

.user-list-table .phone-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.user-list-table .username-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: none; }

.user-list-table .site-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.user-list-table .role-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.user-list-table .action-td {
  width: 15%;
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1; }

.user-list-table .action-div {
  width: 100%; }
  .user-list-table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }
  .user-list-table .action-div .fa-edit {
    font-size: 22px !important;
    color: #0070c0 !important; }
  .user-list-table .action-div .fa-trash {
    font-size: 22px; }

.MuiTypography-h6 {
  display: none; }

/*
============================================================================================
* File Name: route-page.scss
* This file contains all Route Page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.route-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.list-table-div .center-heading {
  text-align: center; }

.route-list-table tr {
  cursor: pointer; }

.route-list-table .normalfont {
  font-weight: normal !important; }

.route-list-table td.route-name-td {
  width: 11%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  padding-left: 10px;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.route-list-table .route-manager-td {
  width: 12.5%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.route-list-table .street-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.route-list-table .city-td {
  width: 6%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.route-list-table .pin-code-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.route-list-table .meter-count-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-align: center; }

.route-list-table .date-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-align: center; }

.route-list-table td.reader-td {
  width: 11%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: lowercase;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.route-list-table .status-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.route-list-table .street {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.route-list-table .meter-count {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-align: center; }

.route-list-table .update {
  width: 8.5%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.route-list-table .status {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.route-list-table .street:hover, .route-list-table .street-td:hover, .route-list-table td.reader-td:hover, .route-list-table route-name-td:hover {
  overflow: visible;
  z-index: -1;
  color: #0070c0;
  white-space: normal; }

.route-list-table .action-td {
  width: 11%;
  font-size: 30px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1; }

.route-list-table .action-div {
  width: 100%;
  margin-left: 12%; }
  .route-list-table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }
  .route-list-table .action-div .fa-bolt {
    padding-left: 12%;
    font-size: 22px; }
  .route-list-table .action-div .change-color-blue {
    color: #0070c0; }
  .route-list-table .action-div .change-color-gray {
    color: #808080; }
  .route-list-table .action-div .fa-safari {
    font-size: 22px;
    color: #0070c0; }
  .route-list-table .action-div .fa-user-plus {
    padding-left: 8%;
    font-size: 22px;
    color: #0070c0; }
  .route-list-table .action-div .fa-edit {
    font-size: 22px; }

.pagination-bar {
  width: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center; }

.action-center {
  text-align: center; }

.align-icon {
  margin-left: 40px; }

@media (max-width: 1024px) {
  .list-table-div {
    width: 100% !important; }
  .route-list-table .meter-count-td {
    width: 5%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important; } }

@media (max-width: 414px) {
  .list-table-div {
    width: 100% !important;
    margin-left: 0% !important; }
  .align-icon {
    margin-left: 0px !important; } }

.searchControl {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid gray;
  cursor: pointer;
  outline: 1px solid white;
  padding-left: 10px; }

/*
============================================================================================
* File Name: create-route-page.scss
* This file contains all create Route Page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.add-route-main-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }
  .add-route-main-view .create-route-div {
    background-color: #fff;
    width: 70%;
    border: 1px;
    margin-top: 3.5%; }
    .add-route-main-view .create-route-div label {
      display: inline-block; }
    .add-route-main-view .create-route-div .route-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px; }
      .add-route-main-view .create-route-div .route-icon-wrapper h4 {
        color: #0070c0;
        font-weight: 700;
        letter-spacing: 1px; }
    .add-route-main-view .create-route-div .list-table-icon {
      font-size: 32px;
      font-weight: bold;
      border-radius: 50%;
      color: #0070c0; }
    .add-route-main-view .create-route-div form {
      padding: 0px 50px;
      margin-bottom: 20px; }
    .add-route-main-view .create-route-div .form-label {
      color: #999998;
      font-family: avenir;
      font-size: 14px;
      margin-bottom: 0;
      margin-left: 8px; }
    .add-route-main-view .create-route-div .form-control {
      background-color: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 4px;
      color: #66615b;
      font-size: 14px;
      transition: background-color 0.3s ease 0s;
      padding-right: 0 !important;
      padding: 7px 12px;
      height: 40px;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .add-route-main-view .create-route-div .form-control:focus {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 !important; }
    .add-route-main-view .create-route-div .add-route-input {
      text-align: left;
      color: #999998;
      border-radius: 8px;
      font-weight: 600;
      width: 100%;
      height: 50px;
      text-transform: none; }
    .add-route-main-view .create-route-div .add-route-input:focus {
      color: black; }
    .add-route-main-view .create-route-div input.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-route-main-view .create-route-div textarea.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-route-main-view .create-route-div .error-msg {
      padding-left: 1.2%;
      font-size: 12px; }
    .add-route-main-view .create-route-div .input-label-group {
      margin-bottom: 20px; }
  @media (min-width: 768px) {
    .add-route-main-view .col-md-6 {
      flex: 0 0 50%;
      max-width: 49.9%; } }

@media (max-width: 414px) {
  .create-route-div {
    width: 100% !important; }
  .ml-5, .mx-5 {
    margin-left: 1rem !important;
    margin-top: 1em !important; }
  .row {
    width: 320px !important;
    margin-left: -1rem !important; } }

@media (max-width: 375px) {
  .create-route-div {
    width: 100% !important; }
  .ml-5, .mx-5 {
    margin-left: 0rem !important;
    margin-top: 1em !important; }
  .row {
    width: 280px !important; } }

@media (max-width: 320px) {
  .row {
    width: 230px !important;
    margin-left: -1.5rem !important; } }

/*
============================================================================================
* File Name: _add-user-page.scss
* This file contains Add User Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.add-user-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }

.create-user-div {
  background-color: #fff;
  width: 70%;
  border: 1px;
  margin-top: 3.5%; }
  .create-user-div label {
    display: inline-block; }
  .create-user-div .user-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px; }
    .create-user-div .user-icon-wrapper h4 {
      color: #0070c0;
      font-weight: 700;
      letter-spacing: 1px; }
  .create-user-div .list-table-icon {
    font-size: 32px;
    font-weight: bold;
    border-radius: 50%;
    color: #0070c0; }
  .create-user-div form {
    padding: 0px 50px;
    margin-bottom: 20px; }
  .create-user-div .form-label {
    color: #999998;
    font-family: avenir;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 8px; }
  .create-user-div .form-control {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    transition: background-color 0.3s ease 0s;
    padding-right: 0 !important;
    padding: 7px 12px;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .create-user-div .form-control:focus {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
  .create-user-div .add-user-input {
    text-align: left;
    color: #999998;
    border-radius: 8px;
    font-weight: 600;
    width: 100%;
    height: 50px;
    text-transform: none; }
  .create-user-div .add-user-input:focus {
    color: black; }
  .create-user-div input.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }
  .create-user-div textarea.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }
  .create-user-div .error-msg {
    padding-left: 1.2%;
    font-size: 12px; }
  .create-user-div .input-label-group {
    margin-bottom: 20px; }

.text-left {
  text-align: left !important; }

.dummy_row {
  height: 20px; }

.reset_button {
  width: 55%;
  margin-top: 14%; }

.mytextstyle {
  background-color: #0070c0;
  color: white;
  font-size: 14px;
  box-sizing: border-box; }

.rowdata {
  margin-left: 2%;
  padding-bottom: 2%;
  width: 21%; }

.reset-button {
  background: #0070c0;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 13px 10px 13px 10px;
  border-radius: 5px;
  margin-top: 13%; }

.cancel_reset_btn {
  width: 100px;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background: #ffffff;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  border: 1px solid grey;
  color: #000000;
  margin-left: -58px;
  margin-top: 70%; }

.cancel_reset_btn:hover {
  background: #f2f2f2; }

@media (max-width: 1024px) {
  .col-md-2 {
    flex: 0 0 23%;
    max-width: 100%; }
  .col-md-3 {
    flex: 0 0 35%;
    max-width: 100%; }
  .reset-button {
    margin-top: 0%;
    margin-left: 0%; }
  .cancel_reset_btn {
    margin-left: -58px;
    margin-top: 6%; }
  .cancel_button {
    width: 105px;
    padding: 7px; } }

@media (max-width: 414px) {
  .col-md-2 {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%; }
  .create-user-div form {
    padding: 0px 35px;
    width: 92%; }
  .create-user-div {
    width: 100%; }
  .rowdata {
    margin-left: 5%;
    width: 90%; }
  .ml-5 {
    margin-left: -1px !important; }
  .reset-button {
    margin-top: 0%;
    margin-left: 0%; }
  .cancel_reset_btn {
    width: 149px;
    margin-left: 0px;
    margin-top: 0%;
    padding: 5px; }
  .cancel_button {
    width: 149px;
    padding: 7px; } }

/*
============================================================================================
* File Name: _spinner.scss
* This file contains Spinner styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }

.graph-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.line-chart-graph-height {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.spinner {
  border: 7px solid rgba(27, 129, 224, 0.1);
  border-top: 7px solid #0817ee;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite; }

.graph-loader {
  width: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(rgb(000, 112, 192) 0 0);
  --r1: radial-gradient(farthest-side at bottom,#000 93%,#0000);
  --r2: radial-gradient(farthest-side at top   ,#000 93%,#0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2);
  background-repeat: no-repeat;
  animation: l1 1s infinite alternate; }

@keyframes l1 {
  0%, 10% {
    background-size: 8px 0,8px 4px,8px 4px;
    background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%, 50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px); }
  90%, 100% {
    background-size: 8px 100%,8px 4px, 8px 4px;
    background-position: 0 50%, 0 -2px, 0 calc(100% + 2px), 50% 50%, 50% -2px, 50% calc(100% + 2px), 100% 50%, 100% -2px, 100% calc(100% + 2px); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*
============================================================================================
* File Name: _meter-reading-page.scss
* This file contains all Meter Page Styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.reading-info-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  background-color: #FFFFFF; }

.reading-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: #FFFFFF; }

.placehoder-text {
  font-weight: 900;
  width: 115px;
  color: #000000; }

.site-wrapper {
  border: solid 1px #ccc;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 15px 30px;
  margin: 20px 3%;
  width: 500px; }

.site-info {
  width: 100%; }

.site-content :nth-child(2) {
  font-size: 15px; }

.content {
  display: inline-block; }
  .content :nth-child(2) {
    font-size: 15px; }

.enable-icon {
  font-size: 32px !important;
  font-weight: bold;
  border-radius: 50%;
  color: #4069a6;
  color: green;
  font-size: 22px !important;
  margin-left: -15%; }

.disable-icon {
  font-size: 32px !important;
  font-weight: bold;
  border-radius: 50%;
  color: #4069a6;
  color: #F2C18E;
  font-size: 22px !important;
  margin-left: -15%; }

.customer-wrapper {
  border: solid 1px #ccc;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 15px 30px;
  margin: 20px 3%;
  width: 550px; }

.cust-info {
  width: 100%; }

.vertical-align {
  vertical-align: middle !important; }

.reading-list-table tr {
  cursor: pointer; }

.reading-list-table .serial-no-td {
  width: 11%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.reading-list-table .route-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important; }

.reading-list-table .sird-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important; }

.reading-list-table .reader-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.reading-list-table .unit-td {
  width: 5%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  text-transform: none; }

.reading-list-table .meter-status-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: green;
  vertical-align: middle !important;
  text-transform: none !important; }

.reading-list-table .date-td {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  text-transform: lowercase;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.reading-list-table .date-td:hover {
  overflow: visible;
  z-index: -1;
  color: #0070c0;
  white-space: normal; }

.reading-list-table .reading-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  padding-left: 20px; }

.reading-list-table .alarm-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important; }

.unread {
  color: #FA8072 !important; }

.alarm {
  color: #F2C18E !important; }

.manual {
  color: #000000 !important; }

.read {
  color: #72d68c !important; }

.sync-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-left: -20px; }
  .sync-wrapper label {
    font-size: 16px !important; }

@media (max-width: 414px) {
  .sync-responsive {
    margin-top: -74%;
    margin-left: -35%; } }

.custom_modal {
  position: fixed;
  top: 15%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  overflow: scroll; }

.custom_modal .modal_body {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  /* background: #fff; */
  max-width: 935px;
  margin: auto;
  border-radius: 5px;
  max-height: 600px;
  padding: 26px 22px 22px 22px;
  box-shadow: 0 0px 9px #000;
  background: #fff;
  overflow-y: scroll;
  margin-top: 10px !important; }

.custom_modal.fade {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out,-o-transform .3s ease-out; }

.custom_modal .modal_title {
  margin-top: 0px;
  font-size: 18px;
  font-size: 18px;
  color: #0c3850; }

.meter-data-header {
  color: grey;
  letter-spacing: 1px; }

.totalizer-and-flowrate-header {
  display: flex;
  flex-direction: row;
  max-width: 100%; }

.live-sync-icon-and-text-container.totalizer-and-flowrate {
  display: flex;
  flex-direction: row; }

.flow-image-meter-readings {
  width: 100px;
  height: 100px;
  display: flex;
  padding-left: 15px; }

.totalizer-date-div {
  flex: 1;
  max-width: 49.5%;
  flex-direction: row;
  border-radius: 10px;
  border: 5px solid black; }

.flowrate-date-div {
  flex: 2;
  max-width: 50%;
  flex-direction: row;
  border-radius: 10px;
  border: 5px solid black; }

/*
============================================================================================
* File Name: _site-page.scss
* This file contains all Meter Page Styles
* and stored in assets/css/main.css
* Author: Sanjana Dolas
============================================================================================
*/
.site_input_class {
  height: 100%;
  margin: 15px;
  border-radius: 5px;
  border-width: 1px;
  padding: 8px;
  outline: none;
  border: 1px solid lightgrey;
  font-size: 16px;
  transition: all 0.3s ease;
  align-items: center; }

.site-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.add-button {
  background: #0070c0;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px; }

.site-list-table tr {
  cursor: pointer;
  font-size: 16px; }

.site-list-table .site-name-td {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.site-list-table .site-street-td {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.site-list-table .site-city-td {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.site-list-table .site-pincode-td {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.site-list-table .action-td {
  width: 20%;
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  color: #0070c0;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1; }

.site-list-table .action-div {
  width: 100%; }
  .site-list-table .action-div .fa-edit {
    font-size: 22px;
    color: #0070c0; }
  .site-list-table .action-div .fa-trash {
    font-size: 22px; }

@media (max-width: 1024px) {
  .list-table {
    width: 100% !important; } }

@media (max-width: 414px) {
  .list-table {
    width: 100% !important; } }

.copy-icon {
  font-size: 14px !important;
  cursor: pointer;
  margin-left: 0px; }

.site-url-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  overflow-wrap: break-word; }

.copied-text {
  color: limegreen !important; }

/*
============================================================================================
* File Name: _add-site-page.scss
* This file contains Add Site Page styles
* and stored in assets/css/main.css
* Author: Sanjana Dolas
============================================================================================
*/
.add-site-main-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }
  .add-site-main-view .create-site-div {
    background-color: #fff;
    width: 70%;
    border: 1px;
    margin-top: 3.5%; }
    .add-site-main-view .create-site-div label {
      display: inline-block; }
    .add-site-main-view .create-site-div .site-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px; }
      .add-site-main-view .create-site-div .site-icon-wrapper h4 {
        color: #0070c0;
        font-weight: 700;
        letter-spacing: 1px; }
    .add-site-main-view .create-site-div .list-table-icon {
      font-size: 32px;
      font-weight: bold;
      border-radius: 50%;
      color: #0070c0; }
    .add-site-main-view .create-site-div form {
      padding: 0px 50px;
      margin-bottom: 20px; }
    .add-site-main-view .create-site-div .form-label {
      color: #999998;
      font-family: avenir;
      font-size: 14px;
      margin-bottom: 0;
      margin-left: 8px; }
    .add-site-main-view .create-site-div .form-control {
      background-color: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 4px;
      color: #66615b;
      font-size: 14px;
      transition: background-color 0.3s ease 0s;
      padding-right: 0 !important;
      padding: 7px 12px;
      height: 40px;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .add-site-main-view .create-site-div .form-control:focus {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 !important; }
    .add-site-main-view .create-site-div .add-meter-input {
      text-align: left;
      color: #999998;
      border-radius: 8px;
      font-weight: 600;
      width: 100%;
      height: 50px;
      text-transform: none; }
    .add-site-main-view .create-site-div .add-meter-input:focus {
      color: black; }
    .add-site-main-view .create-site-div input.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-site-main-view .create-site-div textarea.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-site-main-view .create-site-div .error-msg {
      padding-left: 1.2%;
      font-size: 12px; }
    .add-site-main-view .create-site-div .input-label-group {
      margin-bottom: 20px; }

@media (max-width: 414px) {
  .add-site-main-view .create-site-div {
    width: 100%; } }

/*
============================================================================================
* File Name: _footer.scss
* This file contains all footer Styles
* and stored in assets/css/main.css
* Author: Sanjana
============================================================================================
*/
.main-footer {
  padding: 1rem;
  background-color: rgba(167, 168, 187, 0.493);
  text-align: center; }

/*
============================================================================================
* File Name: route-meter-mapping.scss
* This file contains all Route Meter Mapping page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.AddMeter {
  width: 40%;
  margin-left: 4%; }

.AddButton {
  margin-top: 1.3%;
  padding: 10px; }

.add-meter-input {
  text-align: left;
  color: #999998;
  border-radius: 8px;
  font-weight: 600;
  width: 100%; }

.form-control {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: #66615b;
  font-size: 14px;
  transition: background-color 0.3s ease 0s;
  padding-right: 0 !important;
  padding: 7px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control:focus {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important; }

.xls-btn {
  background-color: #0070c0 !important; }

.list-table .serialNo-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.list-table .meterType-td {
  width: 17%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.list-table .lastUpdatedReading-td {
  width: 11%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.list-table .lastUpdatedDate-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.list-table .unit-td {
  width: 5%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: none; }

.list-table .meterStatus-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.list-table .alarms-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.list-table .consumerNo-td {
  width: 14%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.list-table .action-td {
  width: 12%;
  font-size: 25px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  padding-left: 10px; }

.list-table .action-div {
  width: 100%;
  padding-right: 10px; }
  .list-table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }

@media (min-width: 1024px) {
  .list-table .consumerNo-td {
    width: 10%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #000000;
    vertical-align: middle !important;
    text-transform: none !important; }
  .list-table .action-td {
    width: 13%;
    font-size: 25px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important; } }

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 49.9%; } }

@media (max-width: 414px) {
  .brand-button {
    margin-left: 50%;
    margin-top: 20px; }
  .AddMeter {
    width: 100%;
    margin-left: 10%; }
  .list-table-div {
    width: 100%;
    margin-left: 0%; }
  .reading-wrapper {
    display: inline-block; }
  .reading-info-outer {
    margin-left: 0px;
    margin-right: 0px; }
  .ml-3 {
    margin-left: 0rem !important; }
  .AddButton {
    margin-left: 20px; }
  .site-wrapper, .customer-wrapper {
    width: 360px;
    margin-left: 15px; } }

@media (max-width: 375px) {
  .site-wrapper, .customer-wrapper {
    width: 337px; } }

@media (max-width: 320px) {
  .brand-button {
    margin-left: 35%;
    margin-top: 20px; }
  .site-wrapper, .customer-wrapper {
    width: 280px; } }

/*
============================================================================================
* File Name: pagination.scss
* This file contains all Route Page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.pagination-container {
  display: flex;
  list-style-type: none; }
  .pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    color: #0070c0;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 18px;
    min-width: 32px; }
    .pagination-container .pagination-item.dots:hover {
      background-color: transparent;
      cursor: default; }
    .pagination-container .pagination-item:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer; }
    .pagination-container .pagination-item.selected {
      background-color: rgba(0, 0, 0, 0.08); }
    .pagination-container .pagination-item .arrow::before {
      position: relative;
      content: '';
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.12em solid rgba(0, 0, 0, 0.87);
      border-top: 0.12em solid rgba(0, 0, 0, 0.87); }
    .pagination-container .pagination-item .arrow.left {
      transform: rotate(-135deg) translate(-50%); }
    .pagination-container .pagination-item .arrow.right {
      transform: rotate(45deg); }
    .pagination-container .pagination-item.disabled {
      pointer-events: none; }
      .pagination-container .pagination-item.disabled .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43); }
      .pagination-container .pagination-item.disabled:hover {
        background-color: transparent;
        cursor: default; }

.paging {
  width: 40px;
  height: 40px; }

.recordPerPage {
  margin-left: 80vw;
  width: 100%;
  margin-top: -2.5%;
  font-size: 13px !important; }
  .recordPerPage select {
    color: #0070c0; }

.selectDropdown {
  width: 40%;
  height: 5%;
  margin-left: 8vw;
  margin-top: -14%;
  color: #0070c0; }

/*
============================================================================================
* File Name: _role-page.scss
* This file contains Role Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.role-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 20px; }
  .role-wrapper .form-control {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    transition: background-color 0.3s ease 0s;
    padding-right: 0 !important;
    padding: 7px 12px;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 250px !important; }
  .role-wrapper .subzone-space {
    padding-left: 10px; }
  .role-wrapper .form-control:focus {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
  .role-wrapper .add-role-input {
    text-align: left;
    color: #999998;
    border-radius: 8px;
    font-weight: 600;
    width: 100%;
    height: 50px;
    text-transform: none; }
  .role-wrapper .add-role-input:focus {
    color: black; }
  .role-wrapper input.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }
  .role-wrapper textarea.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }
  .role-wrapper .error-msg {
    padding-left: 1.2%;
    font-size: 12px; }
  .role-wrapper .input-label-group {
    margin-bottom: 20px; }
  .role-wrapper .input-div {
    display: flex;
    justify-content: center; }
  .role-wrapper .role-button {
    height: 50px; }
  .role-wrapper .user-table-div {
    background-color: white;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: 45%; }
  .role-wrapper .role-list-table .sr-td {
    width: 50%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important;
    text-align: center; }
  .role-wrapper .role-list-table .role-td {
    width: 60%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #000000;
    vertical-align: middle !important;
    text-transform: none !important; }
  .role-wrapper .role-list-table .action-td {
    width: 20%;
    font-size: 22px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    line-height: 1; }
  .role-wrapper .role-list-table .action-div {
    width: 100%; }
    .role-wrapper .role-list-table .action-div :nth-child(2) {
      margin-left: 15px;
      font-size: 22px; }
  @media (max-width: 1024px) {
    .role-wrapper .user-table-div {
      width: 50%; } }
  @media (max-width: 414px) {
    .role-wrapper .user-table-div {
      width: 100%; }
    .role-wrapper .input-div {
      display: inline; } }

/*
============================================================================================
* File Name: route-assignment-page.scss
* This file contains all Route Assignment page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.addUser {
  width: 44%;
  margin-left: 4%;
  margin-top: 1%; }

.addButton {
  text-align: center;
  margin-top: 2%; }

.userFullName-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.transaction-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal !important; }

.userEmail-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal !important;
  text-transform: none; }

.userPhone-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal !important; }

.userName-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal !important;
  text-transform: none; }

.userSite-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal !important; }

.assigned-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal !important; }

.assignment-button {
  width: 15%; }

.calender-div {
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 4px; }
  .calender-div .form-control, .calender-div brand-font, .calender-div p-3 {
    border: none; }
  .calender-div .fa-calendar {
    margin-top: 6px;
    margin-left: 6px; }

@media (max-width: 1112px) {
  .assignment-button {
    width: 20%; } }

@media (max-width: 1024px) {
  .assignment-button {
    width: 20%; }
  .customer-wrapper {
    width: 360px; } }

@media (max-width: 414px) {
  .addUser {
    margin-left: 25px; }
  .assignment-button {
    width: 45%; }
  .brand-button {
    margin-left: 0px;
    margin-right: 0px; }
  .addButton {
    text-align: left;
    margin-left: 5%; }
  ml-3 {
    margin-left: 0rem !important; }
  .customer-wrapper {
    width: 360px; } }

@media (max-width: 375px) {
  .customer-wrapper {
    width: 337px; } }

@media (max-width: 320px) {
  .assignment-button {
    width: 55%; }
  .customer-wrapper {
    width: 280px; } }

/*
============================================================================================
* File Name: Report Page.scss
* This file contains all Report Page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.report-button {
  width: 10%;
  font-size: 12px; }

.download-button {
  width: auto !important;
  margin-left: 2%;
  font-size: 12px; }

.brandxls-button {
  background: gray;
  color: #ffffff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px; }

.mystyle {
  background-color: #0070c0;
  color: white;
  font-size: 12px;
  box-sizing: border-box; }

.enabaled-text {
  font-weight: 600 !important; }

.report-button-big {
  width: auto !important;
  font-size: 13px;
  font-weight: 500; }

.loader {
  width: 25px;
  height: 25px;
  border: 4px solid #131212;
  border-bottom-color: transparent;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite; }

@keyframes rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media (max-width: 1112px) {
  .addButton #button {
    width: 15%; } }

@media (max-width: 1024px) {
  .addButton #button {
    width: 20%; }
  .addButton #test-table-xls-button {
    width: 20%; } }

@media (max-width: 414px) {
  .row {
    margin-left: 20px;
    width: 570px; }
  .addUser {
    width: 270px; }
  .addButton #button, .addButton #test-table-xls-button {
    width: 50%;
    margin-top: 10px; }
  .addButton #test-table-xls-button {
    margin-left: 1px; } }

@media (max-width: 375px) {
  .row {
    width: 500px; }
  .addUser {
    width: 240px; } }

@media (max-width: 320px) {
  .row {
    margin-left: 5px;
    width: 460px; }
  .addUser {
    width: 220px; }
  .addButton #test-table-xls-button {
    width: 60%; } }

/*
============================================================================================
* File Name: sidebar.scss
* This file contains all sidebar Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.side-navigation-bar-container {
  position: fixed;
  z-index: 999; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-hamburger-button {
    width: 100%;
    border-radius: 5px;
    margin: 5.35px 0 5px 0;
    box-shadow: 0.5px 0.5px 6px 1px rgba(100, 100, 100, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-hamburger-button span.side-navigation-bar-icon {
      font-size: 20px; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content {
    height: 75vh; }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner {
      border-radius: 5px;
      box-shadow: 0.5px 0.5px 6px 1px rgba(100, 100, 100, 0.2);
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px); }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
        display: none; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu {
          padding: 0; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper {
            background-color: whitesmoke; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper span.pro-icon i.side-navigation-bar-icon {
              font-size: 20px;
              color: #0070c0; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content {
            color: black; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu {
        padding: 0px; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item {
          padding: 7px 35px 5px 20px !important; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper {
            background-color: transparent; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper span.pro-icon i.side-navigation-bar-icon {
              font-size: 20px;
              color: #0070c0; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content {
            color: black; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content a.side-navigation-bar-menu-title {
              color: black; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item:hover {
          background-color: whitesmoke; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown {
          padding: 0;
          background-color: rgba(0, 112, 192, 0.9); }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul {
            padding: 2px; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul li.pro-menu-item div.pro-inner-item {
              padding: 8px 30px 8px 20px; }
              .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul li.pro-menu-item div.pro-inner-item span.pro-item-content a.side-navigation-bar-menu-title {
                color: white; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul li.pro-menu-item:hover {
              background-color: #0070c0; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element {
          padding: 0;
          background-color: transparent;
          left: 80px !important;
          transform: translate(0, 0) !important; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element div.popper-inner {
            padding: 0;
            box-shadow: 0.5px 0.5px 6px 1px rgba(100, 100, 100, 0.2);
            background-color: white; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element div.popper-inner ul {
              padding: 0px; }
              .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element div.popper-inner ul li.pro-menu-item div.pro-inner-item {
                padding: 6px 30px !important; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu#side-navigation-bar-sub-menu-route div.pro-inner-list-item.popper-element {
          top: 0px !important; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu#side-navigation-bar-sub-menu-user div.pro-inner-list-item.popper-element {
          top: 150px !important; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu#side-navigation-bar-sub-menu-report div.pro-inner-list-item.popper-element {
          top: 250px !important; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu#side-navigation-bar-sub-menu-billing div.pro-inner-list-item.popper-element {
          top: 300px !important; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu {
        padding: 0; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper {
          background-color: transparent; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper span.pro-icon i.side-navigation-bar-icon {
            font-size: 20px;
            color: #0070c0; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content {
          color: black; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item:hover {
          background-color: whitesmoke; }

@media (max-width: 768px) {
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-hamburger-button {
    width: 30px;
    margin: 5px 0; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar {
    width: auto !important;
    height: auto !important;
    left: 0px !important; }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner {
      height: 70vh; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
        display: block; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar.collapsed {
    width: 80px !important;
    left: -80px !important; } }

/*
============================================================================================
* File Name: route-history.scss
* This file contains all route-history page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.reading-list-table .date-td {
  font-weight: normal;
  color: #66615b;
  font-size: 14px; }

.reading-list-table .route-name-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.reading-list-table .route-manager-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.reading-list-table .reader-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.reading-list-table .street-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.reading-list-table .city-td {
  width: 5%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.reading-list-table .pin-code-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.reading-list-table .status-td {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.reading-list-table .meter-count-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.reading-list-table .action-div {
  width: 100%;
  color: #0070c0;
  margin-left: 6%; }
  .reading-list-table .action-div .fa-tachometer {
    font-size: 22px; }

/*
============================================================================================
* File Name: _customer-page.scss
* This file contains all Customer Page styles
* and stored in assets/css/main.css
* Author: Sanjana Dolas
============================================================================================
*/
.customer_input_class {
  height: 100%;
  margin: 15px;
  border-radius: 5px;
  border-width: 1px;
  padding: 8px;
  outline: none;
  border: 1px solid lightgrey;
  font-size: 16px;
  transition: all 0.3s ease; }

.customer-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.customer-add-button {
  background: #0070c0;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  align-items: center; }

.table tr {
  cursor: pointer;
  font-size: 16px; }

.table .customer-name-td {
  width: 14%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.table .customer-consumer-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-serial-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-address1-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.table .customer-address2-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.table .customer-street-td {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-city-td {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-pincode-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-mobile-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-address2-td:hover, .table .customer-address1-td:hover {
  overflow: visible;
  z-index: -1;
  color: #0070c0;
  white-space: normal; }

.table .action-td {
  width: 20%;
  font-size: 30px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1; }

.table .action-div {
  width: 100%; }
  .table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }
  .table .action-div .fa-eye {
    color: #0070c0; }

.table td {
  font-weight: 700; }

@media (max-width: 1024px) {
  .list-table-div {
    width: 100% !important; }
  .table .customer-consumer-td {
    width: 10%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important; }
  .table .customer-address1-td {
    width: 8%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important;
    font-weight: normal; } }

@media (max-width: 414px) {
  .list-table-div {
    width: 100% !important; }
  .align-icon {
    margin-left: 0px !important; } }

/*
============================================================================================
* File Name: forget-password.scss
* This file contains all forget Password page Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.password-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column; }
  .password-wrapper .password-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 10px 40px;
    width: 450px; }
    .password-wrapper .password-container .input-password-div,
    .password-wrapper .password-container .password-button-view {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 10px; }
      .password-wrapper .password-container .input-password-div .username-label,
      .password-wrapper .password-container .password-button-view .username-label {
        color: #000000;
        font-weight: 800;
        font-size: 14px !important; }
      .password-wrapper .password-container .input-password-div .username-input,
      .password-wrapper .password-container .password-button-view .username-input {
        width: 300px;
        padding: 10px 16px;
        text-transform: lowercase; }
      .password-wrapper .password-container .input-password-div .recover-button,
      .password-wrapper .password-container .password-button-view .recover-button {
        color: #FFFFFF;
        background-color: #0070c0;
        border: none;
        padding: 10px 20px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase; }
      .password-wrapper .password-container .input-password-div .code-label,
      .password-wrapper .password-container .password-button-view .code-label {
        font-size: 12px;
        color: #000000; }
      .password-wrapper .password-container .input-password-div .code-input,
      .password-wrapper .password-container .password-button-view .code-input {
        width: 350px;
        padding: 5px 12px; }
      .password-wrapper .password-container .input-password-div .password-format,
      .password-wrapper .password-container .password-button-view .password-format {
        font-size: 10px;
        color: red; }

.address-section {
  width: 650px; }

@media (max-width: 414px) {
  .address-section {
    width: auto; } }

.other-field-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

/*
============================================================================================
* File Name: _back-button.scss
* This file contains all footer Styles
* and stored in assets/css/main.css
* Author: Sanjana
============================================================================================
*/
.back-button {
  font-size: 14px;
  color: #0070c0;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 20%;
  border-radius: 4px;
  border: 5px;
  background-color: transparent; }

.back-role-page {
  margin-top: -4%;
  margin-left: -79%;
  margin-bottom: 2%; }

.placeholder-class::-webkit-input-placeholder {
  font-style: normal !important; }

.list-table .routename-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.list-table .startdate-td, .list-table .reminder-td, .list-table .emailsent-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

/*
============================================================================================
* File Name: _billing-invoice.scss
* This file contains all invoice styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.billing-outer-div input,
.billing-outer-div tr,
.billing-outer-div td,
.billing-outer-div p,
.billing-outer-div div {
  font-weight: 400;
  color: #000000; }

.invoice-preview-div {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-bottom: 20px; }

.print-pdf-btn {
  background-color: #0070c0;
  color: #FFFFFF; }

/*
============================================================================================
* File Name: _billing-setup.scss
* This file contains billing setup module styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.billing-setup-form-outer-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }
  .billing-setup-form-outer-div .create-billing-div {
    background-color: #fff;
    width: 70%;
    border: 1px;
    margin-top: 3.5%; }
    .billing-setup-form-outer-div .create-billing-div .unit-btn-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
    .billing-setup-form-outer-div .create-billing-div .empty-unit-div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border: 1px dotted #000000;
      font-size: 14px;
      font-weight: 600; }
    .billing-setup-form-outer-div .create-billing-div .unit-input {
      width: 100%;
      min-height: 38px;
      padding: 2px 8px; }
  .billing-setup-form-outer-div .billing-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px; }
    .billing-setup-form-outer-div .billing-icon-wrapper h4 {
      color: #0070c0;
      font-weight: 700;
      letter-spacing: 1px; }
  .billing-setup-form-outer-div label {
    display: inline-block; }
  .billing-setup-form-outer-div form {
    padding: 0px 50px;
    margin-bottom: 20px; }
  .billing-setup-form-outer-div .form-label {
    color: #999998;
    font-family: avenir;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 8px; }
  .billing-setup-form-outer-div .form-control {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    transition: background-color 0.3s ease 0s;
    padding-right: 0 !important;
    padding: 7px 12px;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .billing-setup-form-outer-div .form-control:focus {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
  .billing-setup-form-outer-div .form-input {
    text-align: left;
    color: #999998;
    border-radius: 8px;
    font-weight: 600;
    width: 100%;
    height: 50px;
    text-transform: none; }
  .billing-setup-form-outer-div .form-input:focus {
    color: black; }
  .billing-setup-form-outer-div input.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }
  .billing-setup-form-outer-div textarea.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }

.history-list-table .serial-no-td {
  width: 11%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.history-list-table .username-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  text-transform: none !important; }

.history-list-table .name-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important; }

.history-list-table .email-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.history-list-table .meter-status-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: capitalize !important; }

.history-list-table .meter-data-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: capitalize !important; }

.history-list-table .reason-td {
  width: 38%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

/* MainHomePage.scss */
/* Main container styles */
.main-container-home-page {
  display: flex;
  flex-direction: column;
  /* Stack sections vertically */
  align-items: center;
  /* Center content horizontally */
  gap: 20px;
  /* Space between sections */
  padding: 20px;
  /* Padding around the main container */ }

/* Section styles for demo papers, pie charts, and line charts */
.demo-papers-container {
  display: flex;
  flex-direction: row;
  /* Arrange items in a row */
  justify-content: space-between;
  /* Space items evenly */
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  width: 150%;
  /* Full width of the parent */
  max-width: 1200px;
  /* Optional: limit the max width */ }
  .demo-papers-container flow-section {
    height: 180px;
    width: 180px; }

.pie-charts-container,
.line-charts-container {
  display: flex;
  /* Use flexbox for layout */
  flex-direction: row;
  /* Arrange items in a row */
  justify-content: space-between;
  /* Space items evenly */
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  width: 150%;
  /* Full width of the parent */
  max-width: 1200px;
  /* Optional: limit the max width */ }

/* Individual item styles */
.demo-paper {
  width: 230px;
  height: 240px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
  box-shadow: 0px 1px 8px 2px #afaeae;
  border-radius: 10%; }
  .demo-paper img {
    width: 80%;
    height: 80%;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .demo-paper:hover {
    box-shadow: 0px 1px 8px 2px rgba(250, 102, 243, 0.61); }
  .demo-paper.bg-light-blue {
    background-color: #DFF1FF; }
  .demo-paper.bg-light-orange {
    background-color: #FEE7DA; }
  .demo-paper.bg-light-yellow {
    background-color: #FEF7D9; }
  .demo-paper.bg-light-green {
    background-color: #ddf6dd; }
  .demo-paper.bg-light-pink {
    background-color: #FADCD9; }

.pie-chart-container,
.line-chart-container {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Shadow for depth */
  padding: 20px;
  /* Padding inside each item */
  border-radius: 8px;
  /* Rounded corners */
  margin: 10px;
  justify-content: space-between;
  /* Margin for spacing between items */
  background-color: #fff;
  /* Background color for items */ }

.map-marker-icon-flow {
  color: #0070c0; }

.map-marker-icon-pressure {
  color: red; }

.map-marker-icon-level {
  color: yellow; }

.map-marker-icon-tempereture {
  color: green; }

/* Optional: Responsive adjustments */
.small-chart-individual {
  width: 24%;
  background-color: white;
  border-radius: 6px;
  cursor: pointer; }
  .small-chart-individual .small-chart-label {
    padding: 10px 5%;
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    color: white; }
  .small-chart-individual.compliance-small-chart .small-chart-label {
    background-color: #0070c0; }
  .small-chart-individual .small-chart-content {
    display: flex;
    justify-content: center;
    padding: 5px; }
    .small-chart-individual .small-chart-content .plotly-small-chart {
      display: flex;
      height: 220px;
      width: 100%;
      justify-content: center;
      align-items: stretch; }
    .small-chart-individual .small-chart-content .plotly-small-chart-no-data {
      display: flex;
      height: 220px;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center; }

.report-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; }

.addUser-one {
  width: 24%;
  margin-left: -43px; }

.addButton-home-page {
  display: flex;
  justify-content: center;
  margin-top: 10px; }

.brand-button-home-page {
  width: 100%;
  padding: 3px;
  background-color: #0070c0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px; }

.brand-button-.addButton-home-page {
  background-color: #0056b3; }

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 0; }

.card {
  max-width: 200px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  width: auto; }

.flow-card {
  background-color: #fff8f8; }

.pressure-card {
  background-color: #FEF7D9; }

.level-card {
  background-color: #f6fdf6; }

.temperature-card {
  background-color: #fafafa; }

.live-sync-icon-and-text-container {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
  .live-sync-icon-and-text-container .totalizer-graph {
    flex: 0 0 48%;
    text-align: center;
    border: 2px solid black; }
  .live-sync-icon-and-text-container .flowrate-graph {
    flex: 0 0 48%;
    text-align: center;
    border: 2px solid black; }
  .live-sync-icon-and-text-container .flow-image {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .live-sync-icon-and-text-container .flow-image img {
      width: 70px;
      height: 70px;
      object-fit: cover; }
  .live-sync-icon-and-text-container .flow-heading {
    letter-spacing: 3px;
    font-size: 30px;
    color: #0070c0;
    font-weight: 700;
    font-family: "Roboto", "Poppins", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }
  .live-sync-icon-and-text-container .live-sync-info {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .live-sync-icon-and-text-container .live-sync-info i.fa-refresh.icon {
      margin: 0 10px; }
    .live-sync-icon-and-text-container .live-sync-info .enable-icon, .live-sync-icon-and-text-container .live-sync-info .disable-icon {
      margin: 0px; }

.live-sync-icon-after-text-container {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
  .live-sync-icon-after-text-container.filter-changes {
    display: flex;
    width: 100%;
    padding-top: 2%; }
  .live-sync-icon-after-text-container .flow-graph {
    letter-spacing: 3px;
    font-size: 30px;
    color: #0070c0;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.zone-select-container,
.subzone-select-container {
  width: 25%;
  margin-left: 10px;
  margin-right: 20px; }
  .zone-select-container.subzone-select-container,
  .subzone-select-container.subzone-select-container {
    margin-left: 20px; }

.sticky-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0070c0;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); }

.report-section {
  height: 50px;
  color: #3412f5;
  background-color: rgba(229, 226, 226, 0.986);
  border-radius: 5px;
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  width: 100%;
  font-weight: 600;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.icon-space {
  margin-right: 10px;
  font-weight: 600;
  color: #0070c0; }

.line-chart {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-items: center;
  justify-content: center; }

.live-sync-icon-after-text-container-meter-readings-totalizer {
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 49.5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

.live-sync-icon-after-text-container-meter-readings-flowrate {
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 0 20px 0;
  background-color: white;
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 49.5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

.line-graph-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500; }

.report-section-one {
  height: 50px;
  color: #3412f5;
  background-color: rgba(229, 226, 226, 0.986);
  border-radius: 5px;
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  width: 49.5%;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.report-section-two {
  height: 50px;
  color: #3412f5;
  background-color: rgba(229, 226, 226, 0.986);
  border-radius: 5px;
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  width: 49.5%;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.live-sync-icon-and-text-container {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

.live-sync-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Align icon and label to end */ }
  .live-sync-info i.fa-refresh.icon {
    margin: 0 10px; }
  .live-sync-info .enable-icon, .live-sync-info .disable-icon {
    margin: 0px; }

.level-image {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .level-image img {
    width: 70px;
    height: 70px;
    object-fit: cover; }

.level-heading {
  letter-spacing: 3px;
  font-size: 30px;
  color: #0070c0;
  font-weight: 700;
  font-family: "Roboto", "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.zone-select-container,
.subzone-select-container {
  width: 25%;
  margin-left: 10px;
  margin-right: 20px; }
  .zone-select-container.subzone-select-container,
  .subzone-select-container.subzone-select-container {
    margin-left: 20px; }

.live-sync-icon-after-text-container {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* // border: 2px solid grey;  */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
  .live-sync-icon-after-text-container.filter-changes {
    display: flex;
    width: 100%;
    padding-top: 2%; }
  .live-sync-icon-after-text-container .flow-graph {
    letter-spacing: 3px;
    font-size: 30px;
    color: #0070c0;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.zone-select-container,
.subzone-select-container {
  width: 25%;
  margin-left: 10px;
  margin-right: 20px; }
  .zone-select-container.subzone-select-container,
  .subzone-select-container.subzone-select-container {
    margin-left: 20px; }

.report-section {
  height: 50px;
  color: #3412f5;
  background-color: rgba(229, 226, 226, 0.986);
  border-radius: 5px;
  /* //border: 2px solid grey;  */
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  width: 100%;
  font-weight: 600;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.icon-space {
  margin-right: 10px;
  font-weight: 600;
  color: #0070c0; }

.line-chart {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-items: center;
  justify-content: center; }

.live-sync-icon-after-text-container-meter-readings-totalizer {
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 49.5%;
  /* // border: 2px solid grey;  */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

.live-sync-icon-after-text-container-meter-readings-flowrate {
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 0 20px 0;
  background-color: white;
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 49.5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* // border: 2px solid grey;  */ }

.line-graph-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500; }

.report-section-one {
  height: 50px;
  color: #3412f5;
  background-color: rgba(229, 226, 226, 0.986);
  border-radius: 5px;
  /* //border: 2px solid grey;  */
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  width: 49.5%;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.report-section-two {
  height: 50px;
  color: #3412f5;
  background-color: rgba(229, 226, 226, 0.986);
  border-radius: 5px;
  /* //border: 2px solid grey;  */
  font-size: 20px;
  font-family: "Roboto", "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  width: 49.5%;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }

.sticky-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0070c0;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); }

.live-sync-icon-and-text-container-level-meter-graph {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
